import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function RumblePage() {
  return (
    <TicketshopPage
      scriptString={`<script data-name-filter="rumble" src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-fixr-shop-id="be31447a-9d7b-4108-bde6-c9cb9a5287e1" data-fixr-theme="light"></script>`}
      imageSrc="/logo/rumble-logo.png"
      backgroundSrc="/hero/rumble-hero.jpg"
      imageMaxWidth="462px"
      title="RUMBLE"
      description={`The hardest hitting student garage rave takes over Fridays at the legendary Freedom Mills standing strong as the venues first and only weekly event. Expect resident and guest DJs stepping into the ring each week playing trembling Garage, Speed Garage and Jungle. The place will be decked out in bright hanging foliage, additional lighting and production, strobes and neon RUMBLE branding alongside student drink deals every week.`}
    />
  );
}
